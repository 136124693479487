






























































































































































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import {
	Estoque,
	Loja,
	Usuario,
	EstoqueCsvForm,
	Deposito,
	EstoqueProjection,
} from '@/models'
import { DepositoModule } from '@/store/vuex/deposito/DepositoStore'
import DialogoDeConversaoProduto from '@/components/estoque/DialogoDeConversaoProduto.vue'
import {
	FindDepositoUseCase,
	DeleteEstoqueUseCase,
	FindUsuarioUseCase,
	FindEstoqueUseCase,
	ImportaEstoquesUseCase,
	FindLojaUseCase,
} from '@/usecases'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { DataOptions } from 'vuetify'
import axios, { CancelTokenSource } from 'axios'
import copy from 'copy-to-clipboard'
import { sortConverter } from '@/shareds/paginacao'
import ImportadorDeCsv from '@/components/ui/ImportadorDeCsv.vue'
import { montarOperacaoDeRecurso } from '@/shareds/permissoes-shareds'
import DialogoDePeriodo from './DialogoDePeriodo.vue'
import DialogoDeCsvTabelaDeEstoque from './DialogoDeCsvTabelaDeEstoque.vue'
import DialogoDeRomaneioDeEstoque from './DialogoDeRomaneioDeEstoque.vue'
import DialogoDeTransferenciaDeEstoque from './DialogoDeTransferenciaDeEstoque.vue'
import DialogoDeMovimentacaoDeEstoque from './DialogoDeMovimentacaoDeEstoque.vue'
import DialogoDeDetalhamento from './DialogoDeDetalhamento.vue'

@Component({
	components: {
		DataTableDeCrud,
		DialogoDeConversaoProduto,
		ImportadorDeCsv,
		DialogoDePeriodo,
		DialogoDeCsvTabelaDeEstoque,
		DialogoDeRomaneioDeEstoque,
		DialogoDeTransferenciaDeEstoque,
		DialogoDeMovimentacaoDeEstoque,
		DialogoDeDetalhamento,
	},
})
export default class TelaDeEstoques extends Vue {
	@Ref() dialogoDePeriodo!: DialogoDePeriodo
	@Ref() dialogoCsv!: DialogoDeCsvTabelaDeEstoque
	@Ref() dialogoRomaneio!: DialogoDeRomaneioDeEstoque
	@Ref() dialogoDeTransferencia!: DialogoDeTransferenciaDeEstoque
	@Ref() dialogoDeMovimentacao!: DialogoDeMovimentacaoDeEstoque
	@Ref() dialogoDeDetalhamento!: DialogoDeDetalhamento

	header1 = [
		{ text: 'Nome', value: 'nomeCompletoDoProduto' },
		{ text: 'Loja', value: 'nomeDaLoja' },
		{ text: 'Referência', value: 'skuProdutoPai', sortable: false },
		{ text: 'SKU', value: 'skuDoProduto' },
		{ text: "EAN's", value: 'eansDoProduto', sortable: false, width: '155px' },
		{ text: 'Em Estoque', value: 'qtdeEstoque', align: 'right' },
		{ text: 'Empenhado', value: 'qtdeEmpenhada', align: 'right' },
		{ text: 'Disponível', value: 'qtdeDisponivel', align: 'right' },
		{ text: 'Estoque Minimo', value: 'estoqueMinimo', align: 'right' },
	]

	header2 = [
		{ text: 'Nome', value: 'nomeCompletoDoProduto' },
		{ text: 'Loja', value: 'nomeDaLoja' },
		{ text: 'Referência', value: 'skuProdutoPai', sortable: false },
		{ text: 'SKU', value: 'skuDoProduto' },
		{ text: "EAN's", value: 'eansDoProduto', sortable: false, width: '155px' },
		{ text: 'Em Estoque', value: 'qtdeEstoque', align: 'right' },
		{ text: 'Empenhado', value: 'qtdeEmpenhada', align: 'right' },
		{ text: 'Disponível', value: 'qtdeDisponivel', align: 'right' },
	]

	importaEstoquesUseCase = new ImportaEstoquesUseCase()

	carregando = true

	busca = ''

	copyToClipboard = copy
	AlertModule = AlertModule

	findDepositoUseCase = new FindDepositoUseCase()
	findEstoqueUseCase = new FindEstoqueUseCase()
	deleteUseCase = new DeleteEstoqueUseCase()
	findUsuarioUseCase = new FindUsuarioUseCase()
	findLojaUseCase = new FindLojaUseCase()
	usuario: Usuario | null = null
	estoques: EstoqueProjection[] = []
	paginacao: DataOptions = criarPaginacaoVazia()
	totalRegistros: number | null = null
	cancelTokenDeposito: CancelTokenSource | null = null
	cancelTokenEstoque: CancelTokenSource | null = null
	tabela: Estoque | null = null
	deposito: Deposito | null = null
	carregandoEstoques = false
	atingiuEstoqueMinimo = false
	estoqueDisponivel = false
	mostraCheckbox = false

	created(){
		this.carregarUsuario()
	}

	async carregarUsuario() {
		if (!UserLoginStore.usuario) return
		this.usuario = await this.findUsuarioUseCase.findById(
			UserLoginStore.usuario.id,
		)
	}

	async buscarDeposito() {
		if (!this.idLoja) {
			DepositoModule.setDepositoSelecionado(null)
			this.deposito = null
			this.carregando = false
			return
		}

		try {
			this.carregando = true
			if (this.cancelTokenDeposito) this.cancelTokenDeposito.cancel()
			this.cancelTokenDeposito = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelTokenDeposito.token,
			}
			const depositoPage = await this.findLojaUseCase.findDepositoDaLojaById(this.idLoja,
				axiosConfig,
			)
			if (!depositoPage.content[0]) throw new Error("Deposito da loja não encontrado")
			this.deposito = depositoPage.content[0]
			DepositoModule.setDepositoSelecionado(this.deposito)
		} catch (e) {
			if (axios.isCancel(e)) return
			AlertModule.setError(e)
		} finally {
			this.carregando = false
		}
	}

	async buscarEstoques() {
		if(!this.idLoja){
			this.estoques = []
			this.carregandoEstoques = false
			return 
		}

		try {
			this.carregandoEstoques = true
			if (this.cancelTokenEstoque) this.cancelTokenEstoque.cancel()
			this.cancelTokenEstoque = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelTokenEstoque.token,
			}
			const sorted = sortConverter(this.paginacao)
			const pageEstoques = await this.findEstoqueUseCase.findOtimizado(
				{
					page: this.paginacao.page - 1,
					size: this.paginacao.itemsPerPage,
					sort: [ ...sorted ],
					lojaId: this.idLoja || undefined,
					ean: this.busca || undefined,
					sku: this.busca || undefined,
					nomeDoProduto: this.busca || undefined,
					atingiuEstoqueMinimo: this.atingiuEstoqueMinimo,
					estoqueDisponivel: this.estoqueDisponivel,
					skuPai: this.busca || undefined,
				},
				axiosConfig,
			)
			this.estoques = pageEstoques.content
			this.totalRegistros = pageEstoques.totalElements
		} catch (e) {
			if (axios.isCancel(e)) return
			AlertModule.setError(e)
		} finally {
			this.carregandoEstoques = false
		}

	}

	get itensFormatados(): DisplayItemDeEstoque[] {
		return this.estoques.map(item => ({
			...item,
			qtdeDisponivel: (item.qtdeEstoque - item.qtdeEmpenhada),
			nomeCompletoDoProduto: item.nomeCompletoDoProduto,
			skuDoProduto: item.skuDoProduto,
			eansDoProduto: item.eansDoProduto,
			nomeDaLoja: item.nomeDaLoja,
			estoqueMinimo: item.estoqueMinimo,
			skuProdutoPai: item.skuProdutoPai,
		}))
	}

	get itens() {
		return this.estoques || []
	}

	get idLoja() {
		return this.$route.query.l as string
	}

	get lojasDoUsuarioLogado(): Loja[] | undefined {
		return this.usuario?.lojas
	}

	get podeRealizarOperacoesDeEscritaNoEstoque() {
		return UserLoginStore.temAutorizacao(
			montarOperacaoDeRecurso('alterar', 'depositos'),
		)
	}

	get IdDoDeposito(){
		return this.deposito?.id
	}

	@Watch('idLoja', { immediate: true })
	onChangeIdLoja() {
		if (!this.idLoja) return

		this.busca = (this.$route.query.busca as string | null) || ''
		this.paginacao = criarPaginacaoVazia()
		this.estoques = []
		this.buscarDeposito()
		this.buscarEstoques()
	}

	async removerItem(indice: number) {
		const item = this.itens[indice]
		if (!item.id) return
		if (!this.deposito?.id) return
		try {
			await this.deleteUseCase.delete(this.deposito.id, item.id)
			this.estoques = this.estoques.filter(
				({ id }) => id !== item.id,
			)

			AlertModule.setSuccess('Item removido do estoque')
		} catch (error: any) {
			AlertModule.setError(error)
		}
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao(paginacaoAtual: DataOptions, paginacaoAnterior: DataOptions) {
		if (
			paginacaoAtual.page === paginacaoAnterior.page &&
			paginacaoAtual.itemsPerPage === paginacaoAnterior.itemsPerPage &&
			paginacaoAtual.sortBy.join() === paginacaoAnterior.sortBy.join() &&
			paginacaoAtual.sortDesc.join() === paginacaoAnterior.sortDesc.join()
		) return
		this.buscarEstoques()
	}

	@Watch('busca')
	onChangeBusca() {
		this.paginacao = criarPaginacaoVazia()
		this.buscarEstoques()
	}

	selecionarHeader() {
		if(!this.deposito) return
		return this.deposito.loja.configuracaoDaLoja.alteraEstoqueMinimo ? this.header1 : this.header2
	}

	mostrarCheckobox() {
		if(!this.deposito) return
		return this.deposito?.loja.configuracaoDaLoja.alteraEstoqueMinimo ? this.mostraCheckbox = true : this.mostraCheckbox = false
	}
	
	abrirDialogoDeDetalhamento(indice){
		const estoque = this.estoques[indice] 
		this.dialogoDeDetalhamento.mostrar(estoque, this.idLoja)
	}
}

function criarPaginacaoVazia(): DataOptions {
	return {
		page: 1,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}
} 

export interface DisplayItemDeEstoque extends EstoqueProjection {
	nomeCompletoDoProduto: string
	qtdeDisponivel: number
}

export const verificaItensRepetidos = (itens: EstoqueCsvForm[]) => {
	const temRepetidos = itens.some((element, index) => !!itens.find((e, i) => e.sku === element.sku && i !== index))
	if (temRepetidos)
		return 'Tabela tem itens repetidos'
}
